// src/App.js
import React, { useState } from "react";
import Editor from "./components/Editor";
import "materialize-css/dist/css/materialize.min.css";
import "./index.css";
import { saveAs } from "file-saver";

function App() {
  const [language, setLanguage] = useState("HTML");
  const [code, setCode] = useState("<h1>Hello, World!</h1>");
  const [isNightMode, setIsNightMode] = useState(false);

  const languages = ["HTML", "JavaScript", "SVG", "WebAssembly", "JSON", "XML"];

  const defaultCode = {
    HTML: "<h1>Hello, World!</h1>",
    JavaScript: 'console.log("Hello, World!");',
    SVG: `<svg width="100" height="100">
            <circle cx="50" cy="50" r="40" stroke="black" stroke-width="3" fill="red" />
          </svg>`,
    WebAssembly: `// WebAssembly requires a compiled .wasm file`,
    JSON: '{\n  "message": "Hello, World!"\n}',
    XML: "<message>Hello, World!</message>",
  };

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);
    setCode(defaultCode[selectedLanguage]);
  };

  const handleCodeChange = (newCode) => {
    setCode(newCode);
  };

  const handleRunCode = () => {
    console.log("Running code:", code);
    // Add logic to run code here
  };

  const handleClearCode = () => {
    setCode("");
  };

  const handleNightModeToggle = () => {
    setIsNightMode((prevMode) => !prevMode);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileContent = e.target.result;
        setCode(fileContent);
      };
      reader.readAsText(file);
    }
  };

  const handleDownloadClick = () => {
    const blob = new Blob([code], { type: "text/plain;charset=utf-8" });
    const extension =
      language.toLowerCase() === "json" ? "json" : language.toLowerCase();
    saveAs(blob, `code.${extension}`);
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(code);
  };

  return (
    <div className={isNightMode ? "night-mode" : ""}>
      <nav className="navbar">
        <div className="nav-wrapper">
          <a href="/" className="brand-logo center">
            codeEditor
          </a>
        </div>
      </nav>

      <div className="container">
        <div className="controls">
          <div className="controls-left">
            <input
              type="file"
              accept=".html,.js,.svg,.json,.xml"
              id="uploadFileInput"
              onChange={handleFileUpload}
              style={{ display: "none" }}
            />
            <label htmlFor="uploadFileInput" className="btn">
              <i className="material-icons">file_upload</i>
            </label>
            <button className="btn" onClick={handleDownloadClick}>
              <i className="material-icons">file_download</i>
            </button>
          </div>
          <div className="controls-right">
            <select
              className="browser-default"
              value={language}
              onChange={handleLanguageChange}
            >
              {languages.map((lang) => (
                <option key={lang} value={lang}>
                  {lang}
                </option>
              ))}
            </select>
            <button className="btn" onClick={handleNightModeToggle}>
              <i className="material-icons">
                {isNightMode ? "brightness_7" : "brightness_4"}
              </i>
            </button>
          </div>
        </div>

        <div className="editor-wrapper">
          <Editor
            language={language}
            code={code}
            onRunCode={handleRunCode}
            onClearCode={handleClearCode}
            onCodeChange={handleCodeChange}
            isNightMode={isNightMode}
          />
        </div>

        {/* New Section */}
        <div className="info-section">
          <h2>About Our Tool</h2>
          <p>
            Our code editor provides a versatile environment for writing and
            testing code in various languages. Below, you will find a brief
            description of the supported languages and features of the editor.
          </p>
          <h3>Supported Languages:</h3>
          <ul>
            <li>
              <strong>HTML</strong>: Write and preview HTML code with real-time
              updates.
            </li>
            <li>
              <strong>JavaScript</strong>: Execute JavaScript code directly and
              view results.
            </li>
            <li>
              <strong>JSON</strong>: Format and validate JSON data.
            </li>
            <li>
              <strong>XML</strong>: Edit and view XML code.
            </li>
            <li>
              <strong>WebAssembly</strong>: Integration and execution support.
            </li>
          </ul>
          <h3>Features:</h3>
          <ul>
            <li>Real-time code execution and preview.</li>
            <li>Support for multiple programming languages.</li>
            <li>Syntax highlighting and line numbers.</li>
            <li>Night mode for low-light environments.</li>
          </ul>
          <h3>Documentation & Keyboard Shortcuts:</h3>
          <p>
            <strong>Keyboard Shortcuts:</strong>
            <br />
            <code>Ctrl + Space</code> - Run the code.
            <br />
            <code>Alt + C</code> - Clear the editor.
            <br />
            <strong>Documentation:</strong>
            <br />
            Refer to our documentation for detailed usage instructions and
            advanced features.
          </p>
        </div>
      </div>

      <footer className="footer">
        <div className="footer-content">
          <p>&copy; 2024 MobilePriceUSA.COM All rights reserved.</p>

          <div className="social-media">
            <a
              href="https://facebook.com/changiskhan01"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
