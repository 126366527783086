import React, { useState, useEffect, useRef } from "react";
import CodeMirror from "@uiw/react-codemirror";

const Editor = ({
  language,
  code,
  onRunCode,
  onClearCode,
  onCodeChange,
  isNightMode,
}) => {
  const [output, setOutput] = useState("");
  const iframeRef = useRef(null);

  const handleCodeChange = (value) => {
    onCodeChange(value);
  };

  const handleRunClick = () => {
    switch (language) {
      case "HTML":
      case "SVG":
        const document = iframeRef.current.contentDocument;
        const doc = document.open();
        doc.write(code);
        doc.close();
        break;

      case "JavaScript":
        try {
          setOutput(eval(code)); // Execute JavaScript code
        } catch (err) {
          setOutput(err.toString());
        }
        break;

      case "JSON":
        try {
          const jsonObj = JSON.parse(code);
          setOutput(JSON.stringify(jsonObj, null, 2));
        } catch (err) {
          setOutput("Invalid JSON: " + err.toString());
        }
        break;

      case "XML":
        setOutput(code); // Display XML as-is
        break;

      case "WebAssembly":
        setOutput(
          "WebAssembly execution requires a compiled .wasm file and JavaScript integration."
        );
        break;

      default:
        setOutput("Unsupported language.");
    }
    onRunCode(code);
  };

  const handleClearClick = () => {
    onClearCode();
    setOutput("");
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(code);
    alert("Code copied to clipboard!");
  };

  const handleKeyDown = (event) => {
    if (event.ctrlKey && event.code === "Space") {
      handleRunClick();
    }
    if (event.altKey && event.code === "KeyC") {
      handleClearClick();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [code]);

  return (
    <div className={isNightMode ? "night-mode" : ""}>
      <div className="editor-wrapper">
        <div className="editor-buttons">
          <button className="btn" onClick={handleRunClick}>
            <i className="material-icons">play_arrow</i>
          </button>
          <button className="btn red" onClick={handleClearClick}>
            <i className="material-icons">clear</i>
          </button>
          <button className="btn" onClick={handleCopyClick}>
            <i className="material-icons">copy_all</i>
          </button>
        </div>
        <div className="editor-container">
          <CodeMirror
            value={code}
            options={{
              mode: language.toLowerCase(),
              theme: isNightMode ? "material-darker" : "material",
              lineNumbers: true,
            }}
            onChange={handleCodeChange}
            style={{
              height: "auto",
              width: "100%",
              minHeight: "8em", // Minimum height for code box
              border: isNightMode ? "1px solid #444" : "1px solid #ddd",
            }}
          />
        </div>
      </div>
      <div className="output-section">
        <h5>Output:</h5>
        {["HTML", "SVG"].includes(language) ? (
          <iframe
            ref={iframeRef}
            style={{
              width: "100%",
              height: "300px",
              border: "1px solid #ccc",
              background: "#fff",
            }}
            title="output"
          />
        ) : (
          <pre>{output}</pre>
        )}
      </div>
    </div>
  );
};

export default Editor;
